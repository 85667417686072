<template>
    <v-container>
        <div class="pt-1 px-1 md-content" ref="md-content" v-html="content"></div>
    </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import markdownit from 'markdown-it';

@Component
export default class PrivacyPolicyView extends Vue {
    public content!: string;
    // public md: markdownit | null = null;

    public created() {
        console.log('created');
        const md = markdownit({

        });
        this.content = md.render(this.getContent);
        // this.md = markdownit();
        // console.log(this.md);
    }

    public mounted() {
        const el = this.$refs['md-content'] as HTMLElement;
        el.querySelectorAll('h1, h2').forEach(x => {
            x.classList.add('headline');
        });

        el.querySelectorAll('a').forEach(x => {
            x.setAttribute('rel', 'nofollow');
        });
    }

    private get getContent() {
        return `
`;
    }
}
</script>

<style lang="scss">
.md-content {
    h1, h2, h3, h4, h5, h6 {
        padding-bottom: 0.2em;
    }

    ul {
        margin-bottom: 0.75em;
    }
}
</style>
