import { Component, Prop, Vue } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';

const USER_TOKEN_KEY = 'user_token';

@Component
export class AppStore extends Vue {
    // userAccount: starc.AccountInfoResponse | null = null;
    battleAccount: starc.AccountBattleInfo | null = null;

    public get userToken() {
        return localStorage.getItem(USER_TOKEN_KEY);
    }

    public get isLoggedIn() {
        return typeof this.userToken === 'string';
    }

    public invalidateSession()
    {
        localStorage.removeItem(USER_TOKEN_KEY);
    }
}

export default new AppStore();
