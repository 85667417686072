<template>
    <v-card>
        <v-toolbar
            flat
            dark
            color="grey darken-3"
        >
            <!-- <v-toolbar-title>Donate</v-toolbar-title> -->
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-title>
            <span class="text-h5">Donate</span>
        </v-card-title>
        <v-card-text>
            <!-- https://developer.paypal.com/sdk/donate/ -->
            <!-- <script src="https://www.paypal.com/sdk/js?client-id=BAA_B7YOIVh76fVlnP_vbTJSAySQ6zmr8Uqo0FB2bIGWH-jii-Sqa-Zjaabr4mwPKFTRHKsDNYIu6YTNgo&components=hosted-buttons&disable-funding=venmo&currency=EUR"></script>
            <div id="paypal-container-3GJHWLACKEB7N"></div>
            <script>
              paypal.HostedButtons({
                hostedButtonId: "3GJHWLACKEB7N",
              }).render("#paypal-container-3GJHWLACKEB7N")
            </script> -->
            <form action="https://www.paypal.com/donate" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_donations" />
                <input type="hidden" name="business" value="GRSJTGW43Z7QE" />
                <input type="hidden" name="image_url" :value="require('@/assets/arcade-icon.png')">
                <input type="hidden" name="item_name" :value="itemName" />
                <input type="hidden" name="item_number" :value="itemNumber">
                <input type="hidden" name="custom" value="custom_test">
                <input type="hidden" name="invoice" value="invoice_xxx">
                <input type="hidden" name="currency_code" value="EUR" />

                <v-text-field
                    class="mb-1"
                    dense
                    filled
                    hide-details
                    label="0.00"
                    clearable
                />
                <v-text-field
                    class="mb-1"
                    dense
                    filled
                    hide-details
                    label="Your nickname (leave blank for anonymous donations)"
                    clearable
                    v-model="username"
                />
                <v-textarea
                    class="mb-1"
                    dense
                    filled
                    hide-details
                    label="Private message (optional)"
                    clearable
                    rows="1"
                    auto-grow
                    v-model="customMessage"
                />
                <v-divider class="py-1"></v-divider>
                <v-btn
                    tile
                    block
                    large
                    color="blue"
                    type="submit"
                >
                    Submit
                </v-btn>
            </form>
        </v-card-text>
        <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
            >
                Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
            >
                Save
            </v-btn>
        </v-card-actions> -->
    </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, PropSync, Emit } from 'vue-property-decorator';

@Component
export default class DonateFormPaypal extends Vue {
    // @PropSync('is-shown', {
    //     type: Boolean,
    // })
    // isShown!: boolean;
    username: string = '';
    customMessage: string = '';
    itemNumber: string = '';

    // close() {
    //     this.isShown = false;
    // }

    @Emit('close')
    close() {
    }

    get itemName() {
        const o: string[] = [];

        o.push('sc2arcade.com');

        if (this.username) {
            o.push(`[${this.username}]`);
        }

        if (this.customMessage) {
            o.push(this.customMessage.replace(/\r?\n/g, ' | '));
        }

        return o.join(' - ');
    }

    created() {
    }

    mounted() {
    }
}
</script>
