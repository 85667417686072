<template>
    <v-container>
        <h2 class="headline">Donate</h2>

        <p>
            If you'd like to see this project grow further or simply stay a float, please help it with any amount you can afford.
        </p>

        <v-row class="justify-center align-center flex-wrap flex-lg-nowrap">
            <v-col cols="12" lg="5" class="text-center">
                <v-dialog
                    v-model="paypalFormDialog"
                    max-width="700px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            disabled
                            tile
                            block
                            large
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon left>fab fa-paypal</v-icon>
                            Donate with PayPal
                            <small>(not yet available)</small>
                        </v-btn>
                    </template>
                    <donate-form-paypal @close="paypalFormDialog = false"/>
                </v-dialog>

                <v-dialog
                    v-model="kofiFormDialog"
                    max-width="700px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="my-6"
                            tile
                            block
                            large
                            color="#ff5f5f"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <svg class="mr-2" width="32" height="32" style="fill: white;" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M23.881 8.948c-.773-4.085-4.859-4.593-4.859-4.593H.723c-.604 0-.679.798-.679.798s-.082 7.324-.022 11.822c.164 2.424 2.586 2.672 2.586 2.672s8.267-.023 11.966-.049c2.438-.426 2.683-2.566 2.658-3.734 4.352.24 7.422-2.831 6.649-6.916zm-11.062 3.511c-1.246 1.453-4.011 3.976-4.011 3.976s-.121.119-.31.023c-.076-.057-.108-.09-.108-.09-.443-.441-3.368-3.049-4.034-3.954-.709-.965-1.041-2.7-.091-3.71.951-1.01 3.005-1.086 4.363.407 0 0 1.565-1.782 3.468-.963 1.904.82 1.832 3.011.723 4.311zm6.173.478c-.928.116-1.682.028-1.682.028V7.284h1.77s1.971.551 1.971 2.638c0 1.913-.985 2.667-2.059 3.015z"/></svg>
                            Donate with Ko-Fi
                        </v-btn>
                    </template>
                    <donate-form-kofi @close="kofiFormDialog = false"/>
                </v-dialog>

                <v-btn
                    class="my-6"
                    tile
                    block
                    large
                    color="white"
                    href="https://www.patreon.com/SC2ArcadeWatcher"
                    target="_blank"
                    disabled
                >
                    <v-icon left>fab fa-patreon</v-icon>
                    Patreon
                    <small>(not yet available)</small>
                </v-btn>

                <!-- <v-btn
                    class="my-6"
                    tile
                    block
                    large
                    color="#ff5f5f"
                    href="https://ko-fi.com/sc2arcade"
                    target="_blank"
                >
                    <svg class="mr-2" width="32" height="32" style="fill: white;" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M23.881 8.948c-.773-4.085-4.859-4.593-4.859-4.593H.723c-.604 0-.679.798-.679.798s-.082 7.324-.022 11.822c.164 2.424 2.586 2.672 2.586 2.672s8.267-.023 11.966-.049c2.438-.426 2.683-2.566 2.658-3.734 4.352.24 7.422-2.831 6.649-6.916zm-11.062 3.511c-1.246 1.453-4.011 3.976-4.011 3.976s-.121.119-.31.023c-.076-.057-.108-.09-.108-.09-.443-.441-3.368-3.049-4.034-3.954-.709-.965-1.041-2.7-.091-3.71.951-1.01 3.005-1.086 4.363.407 0 0 1.565-1.782 3.468-.963 1.904.82 1.832 3.011.723 4.311zm6.173.478c-.928.116-1.682.028-1.682.028V7.284h1.77s1.971.551 1.971 2.638c0 1.913-.985 2.667-2.059 3.015z"/></svg>
                    Ko-Fi
                </v-btn> -->
            </v-col>
        </v-row>

        <h3 class="headline">Past donations</h3>

        <v-simple-table fixed-header>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>From</th>
                        <th class="text-right">Amount</th>
                        <th class="text-right">Method</th>
                    </tr>
                </thead>
                <tbody v-if="donationsData">
                    <tr v-for="(item, i) in recentDonations" :key="i">
                        <td>
                            <span class="font-weight-light">{{ item.date }}</span>
                        </td>
                        <td>
                            <span v-if="item.nickname" class="font-weight-medium body-2">{{ item.nickname }}</span>
                            <span v-else class="font-italic text--secondary">Anonymous</span>
                        </td>
                        <td class="text-right">
                            <span>{{ item.amount.toLocaleString(void 0, { style: 'currency', currency: item.currency }) }}</span>
                        </td>
                        <td class="text-right">
                            <v-icon
                                v-if="item.method === 'PayPal'"
                                size="28"
                                color="primary"
                            >
                                fab fa-paypal
                            </v-icon>
                            <v-icon
                                v-else-if="item.method === 'Patreon'"
                                size="30"
                                color="red"
                            >
                                mdi-patreon
                            </v-icon>
                            <v-icon
                                v-else
                                size="30"
                                color="grey lighten-4"
                            >
                                mdi-bank-transfer-in
                            </v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SGuard } from '../../helpers';
import { DonationsRecentResponse } from '@/starc-api/starc';
import DonateFormPaypal from '@/components/DonateFormPaypal.vue';
import DonateFormKofi from '@/components/DonateFormKofi.vue';

@Component({
    components: {
        DonateFormPaypal,
        DonateFormKofi,
    },
})
export default class InfoDonateView extends Vue {
    donationsData: DonationsRecentResponse = null!;
    paypalFormDialog: boolean = false;
    kofiFormDialog: boolean = false;

    public get recentDonations() {
        return this.donationsData?.donations;
    }

    @SGuard()
    private async fetchRecentDonations() {
        this.donationsData = (await this.$starc.getInfoDonations()).data;
    }

    async created() {
        await this.fetchRecentDonations();
    }
}
</script>
