<template>
    <v-card>
        <v-toolbar
            flat
            color="grey darken-3"
        >
            <!-- <v-toolbar-title>Donate</v-toolbar-title> -->
            <v-btn
                href="https://ko-fi.com/sc2arcade"
                target="_blank"
            >
                <!-- <v-icon left>mdi-close</v-icon> -->
                Open in new window
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <!-- <v-card-title>
            <span class="text-h5">Donate</span>
        </v-card-title> -->
        <v-card-text>
            <iframe class="mt-2" src="https://ko-fi.com/sc2arcade/?hidefeed=true&widget=true&embed=true&preview=true" style="border:none;width:100%;background:transparent;" height="712" title="sc2arcade"></iframe>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, PropSync, Emit } from 'vue-property-decorator';

@Component
export default class DonateFormPaypal extends Vue {
    @Emit('close')
    close() {
    }
}
</script>
