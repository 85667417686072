<template>
    <div class="map-review-item">
        <div class="map" v-if="review.map === null || review.map">
            <!-- {{ review.map.name }} -->
            <router-link
                :to="review.map !== null ? { name: 'map_details', params: { regionId: review.regionId, mapId: review.mapId } } : 'javascript:void(0);'"
                :tag="review.map !== null ? 'a' : 'span'"
                :event="review.map !== null ? 'click' : []"
                class="map-link"
            >
                <template v-if="review.map !== null">
                    <v-list-item-avatar tile>
                        <v-img
                            :src="$starc.depotImage(review.map.iconHash, review.map.regionId).url"
                            :key="`${review.map.regionId,review.map.bnetId}`"
                            width="150"
                            contain
                            >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-list-item-avatar>
                    <h4 v-html="review.map.name"/>
                </template>
                <template v-else>
                    <h4 v-if="review.mapId" v-html="`Unknown map ${review.mapId}`"/>
                </template>
            </router-link>
        </div>
        <div class="author" v-else-if="review.author">
            <profile-item :profile="review.author" portrait-preset="maxw-128" show-discriminator="true"/>
        </div>

        <div class="inner">
            <div class="content">{{ review.body }}</div>
            <div class="meta">
                <div class="rating">
                    <div class="d-inline-block" style="vertical-align: middle; padding-top: 0.25rem; width: 64px;">
                        <small class="grey--text font-weight-normal" style="text-transform: uppercase;">Score: </small>
                        <span :class="`${reviewColor}--text font-weight-bold`">{{ review.rating }}</span>
                    </div>
                    <v-rating
                        readonly
                        :color="reviewColor"
                        background-color="grey"
                        length="5"
                        size="20"
                        hover
                        :value="review.rating"
                        class="d-inline-block"
                    ></v-rating>
                </div>

                <div class="helpful">
                    <span class="text--secondary">
                        <strong>{{ review.helpfulCount }}</strong> helpfuls
                    </span>
                </div>

                <div class="date">
                    <v-tooltip top transition="fade-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                {{ $dfns.formatDistanceStrict(review.updatedAt, Date.now(), {
                                    addSuffix: true,
                                    roundingMethod: 'floor'}) }}
                                <v-icon small v-if="review.createdAt.getTime() !== review.updatedAt.getTime()">mdi-file-edit-outline</v-icon>
                                <br>
                                <small v-if="review.createdAt.getTime() !== review.updatedAt.getTime()">
                                    {{ $dfns.formatDistanceStrict(review.createdAt, Date.now(), {
                                        addSuffix: true,
                                        roundingMethod: 'floor'})
                                    }}
                                </small>
                            </div>
                        </template>
                        <div>
                            Posted on:
                            <strong>{{ $dfns.formatISO9075(new Date(review.createdAt), { representation: 'complete' }) }}</strong>
                        </div>
                        <div v-if="review.createdAt.getTime() !== review.updatedAt.getTime()">
                            Updated on:
                            <strong>{{ $dfns.formatISO9075(new Date(review.updatedAt), { representation: 'complete' }) }}</strong>
                        </div>
                    </v-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import ProfileItem from '@/components/ProfileItem.vue';

@Component({
    components: {
        ProfileItem,
    },
})
export default class MapReviewItem extends Vue {
    @Prop({
        required: true,
    })
    readonly review!: starc.MapUserReview;

    public get reviewColor(): string {
        switch (this.review.rating) {
            case 5: return 'green';
            case 4: return 'teal';
            case 3: return 'blue';
            case 2: return 'orange';
            case 1: return 'red';
            default: return 'grey';
        }
    }

    created() {
    }
}
</script>

<style lang="scss">
.map-review-item {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    padding: 0;

    &:not(:last-child) {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px dashed rgba(#fff, 0.05);
    }

    .author,
    .map {
        display: flex;
        width: 200px;
        min-width: 200px;
        padding: 0.5rem 0;

        text-align: center;
        align-items: flex-start;
        justify-content: center;
    }

    .player-link {
        .v-avatar {
            width: 80px !important;
            height: 80px !important;
        }

        .avatar-img {
            height: 80px !important;
        }

        span {
            display: block;
            margin-left: 0;
            margin-top: 0.2rem;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .map-link {
        text-decoration: none;

        .v-avatar {
            width: 128px !important;
            height: 128px !important;
        }

        .avatar-img {
            height: 128px !important;
        }

        span {
            display: block;
            margin-left: 0rem;
            font-weight: 500;
            font-size: 1.1em;
            letter-spacing: 0.3px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .inner {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        overflow-x: auto;

        >.content {
            flex-grow: 1;
            padding: 0.5rem;
            background: rgba(#fff, 0.025);
            white-space: pre-wrap;
        }
    }

    .inner >.meta {
        display: flex;
        width: 100%;
        padding: 0 0.5rem;
        align-content: center;
        justify-content: space-between;
        margin-top: 0.5rem;

        .helpful {
            font-size: 0.9em;
            line-height: 36px;
        }

        .date {
            color: map-get($blue, 'darken-1');
            font-style: italic;
        }
    }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .map-review-item {
        flex-wrap: wrap;

        .inner {
            flex-wrap: wrap;
        }

        .author,
        .map {
            flex-grow: 1;
            width: 100%;
            min-width: none;
        }

        .meta {
            display: flex;
            flex-wrap: wrap;
        }

        .meta >.rating  {
            flex-grow: 1;
            width: 100%;
        }
    }
}
</style>
